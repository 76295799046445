<template>
  <div>
    <v-app-bar dense fixed app color="pink accent-4" dark>
      <v-btn icon to="/menu_cus">
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-toolbar-title>รายการสาย</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-app-bar>

    <v-breadcrumbs :items="bc_items" class="pa-2" dense>
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <MenuTab @onTabChange="onTabChange" />

    <component v-bind:is="component" />
  </div>
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";

import GenaralTable from "@/components/Cus/Line/Genaral/Datatable";
import CurrentTable from "@/components/Cus/Line/Current/Datatable";
import AccountTable from "@/components/Cus/Line/Account/Datatable";
import MenuTab from "@/components/Cus/Line/Layout/Menu_Tab";

export default {
  name: "Line_Data",
  mixins: [globalFunctionMixin],
  components: {
    GenaralTable,
    CurrentTable,
    AccountTable,
    MenuTab,
  },
  mounted() {
    this.ckLogin();
    this.bc_items[0].text = sessionStorage.getItem("host_id");
  },
  data: () => ({
    component: "GenaralTable",

    bc_items: [
      {
        text: "a1 ถิง",
        disabled: false,
      },
    ],
  }),

  methods: {
    onTabChange(id) {
      switch (id) {
        case 1:
          this.component = GenaralTable;
          break;
        case 2:
          this.component = CurrentTable;
          break;
        case 3:
          this.component = AccountTable;
          break;
      }
      // alert(id)
    },
  },
};
</script>

<style></style>
