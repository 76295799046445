<template>
  <v-tabs v-model="active_tab" background-color="white" show-arrows>
    <v-tabs-slider color="red"></v-tabs-slider>

    <v-tab v-for="tab of tabs" :key="tab.id" @click="tab_change(tab.id)">
      {{ tab.text }}
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  name: "Menu_Tab",

  methods: {
      tab_change(id){
          this.$emit("onTabChange",id)
      },
  },
  data: () => ({
    active_tab: 0,
    tabs: [
      { text: "ทั่วไป", id: 1 },
      { text: "งวดปัจจุบัน", id: 2 },
      { text: "บัญชี", id: 3 },
      { text: "เลขอั้น", id: 4 },
      { text: "อัตราจ่าย", id: 5 },
      { text: "บันทึก", id: 6 },
      { text: "สิทธิการใช้งาน", id: 7 },
    ],
  }),
};
</script>

<style></style>
