<template>
  <div
    style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;"
    unselectable="on"
    onselectstart="return false;"
    onmousedown="return false;"
    id="printSection"
  >
    <v-data-table
      :headers="headers"
      :items="line_data"
      :items-per-page="10"
      item-key="id"
      single-select
      class="elevation-1"
      hide-default-footer
      disable-pagination
      mobile-breakpoint="0"
      height="65vh"
      fixed-header
      dense
    >
      <!-- :class="{'h4': $vuetify.breakpoint. mdAndUp}" -->
      <template v-slot:top>
        <v-toolbar flat>
          <AddData />
          <v-btn rounded color="info ml-2" dark @click="printSection">
            <v-icon left>
              mdi-printer
            </v-icon>
            พิมพ์
          </v-btn>
          <v-divider class="mx-4 info" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn rounded outlined color="pink">
            <v-icon left>
              mdi-filter-variant
            </v-icon>
            ตัวกรอง
          </v-btn>
        </v-toolbar>
        <v-alert dense text class="text-center" type="info">
          แสดง <strong>ทั้งหมด</strong> รวม
          <strong>{{ line_count }}</strong> รายการ
        </v-alert>
      </template>

      <template v-slot:item="{ item }">
        <!-- @contextmenu="popup_menu($event, item)" -->
        <tr
          @touchstart="touch = true"
          @click="row_click($event, item)"
          @contextmenu="popup_menu($event, item)"
          :class="{ 'pink lighten-5': item.id === selectedId }"
        >
          <!-- <td :class="id_column">{{ item.id }}</td> -->
          <td :class="id_column">{{ item.id }}</td>
          <td align="start" :class="getNamesColor(item.Status)">
            {{ item.Nickname }}
          </td>
          <td>{{ item.Password }}</td>
          <td>{{ item.PhoneNumber1 }}</td>
          <td>{{ item.LineAccount }}</td>
          <td align="start" :class="getStatusColor(item.Status)">
            {{ getStatusText(item.Status) }}
          </td>
        </tr>
      </template>
    </v-data-table>

    <PopupMenu ref="PopupMenu1" />
  </div>
</template>

<script>
import PopupMenu from "@/components/Cus/Line/Genaral/Popup_Menu";
import AddData from "@/components/Cus/Line/Genaral/Add_Data";

export default {
  name: "Line_Datatable",

  components: {
    PopupMenu,
    AddData,
  },
  created() {
    //โหลด ตาราง
    this.fetch_table();
  },
  computed: {
    //ดึงข้อมูลจาก vuex มาแสดงในตาราง
    line_data() {
      return this.$store.getters["line/Lines"];
    },
    line_count() {
      return this.$store.getters["line/Lines"].length;
    },
  },
  methods: {
    async fetch_table() {
      let credentials = {
        PeriodDT: sessionStorage.getItem("period"),
        ListType: "genaral_list",
      };
      await this.$store.dispatch("line/get_genaral_list", credentials).then(
        (response) => {
          //console.log(response);
        },
        (error) => {
          console.log(error);
        }
      );
    },
    getStatusText(status) {
      const statusText = ["ปกติ", "ระงับ"];
      return statusText[parseInt(status) - 1];
    },
    getStatusColor(status) {
      const statusColor = ["green--text", "red--text"];
      return statusColor[parseInt(status) - 1] + " pl-10";
    },
    getNamesColor(status) {
      const statusColor = ["", "red--text"];
      return statusColor[parseInt(status) - 1];
    },
    row_click: function(e, item) {
      if (this.selectedId == item.id) {
        if (this.touch == true) this.popup_menu(e, item);
      }

      this.selectedId = item.id;
      this.$set(item, "selected", true);
    },
    popup_menu: function(e, item) {
      e.preventDefault();
      this.$refs.PopupMenu1.Hide(e.clientX, e.clientY);
      this.$nextTick(() => {
        this.$refs.PopupMenu1.Show(item.id);
        this.selectedId = item.id;
        //console.log(item);
      });
    },
    printSection() {
      this.$htmlToPaper("printSection");
    },
  },
  data: () => ({
    selectedId: -1,

    touch: false,
    // // right click
    // showMenu: false,
    // x: 0,
    // y: 0,

    headers: [
      {
        text: "#",
        value: "LineID",
        width: "1%",
      },
      { text: "ชื่อ", value: "Nickkname", align: "start", width: "10%" },
      {
        text: "พาส",
        value: "Password",
        align: "start",
        width: "10%",
        sortable: false,
      },
      {
        text: "เบอร์โทร",
        value: "PhoneNumber1",
        align: "start",
        width: "10%",
      },
      { text: "ไลน์", value: "LineAccount", align: "start", width: "10%" },
      { text: "สถานะ", value: "Status", align: "start", width: "59%" },
    ],
    id_column: "id_column_class",
  }),
};
</script>

<style scoped>
::v-deep .id_column_class {
  background-color: #c51162 !important;
  color: white;
  /* text-align: center !important;  
  padding-top: 10px !important; */
  /* position:absolute;  */
  position: sticky !important;
  width: 3%;
  left: 0;
}

::v-deep table thead th:first-child {
  position: sticky !important;
  left: 0 !important;
  z-index: 3 !important;
  background: #c51162 !important;
  color: white !important;
  padding-top: 15px !important;
}

::v-deep table thead th:last-child {
  padding-left: 40px !important;
}

::v-deep table thead th {
  padding: 3px;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 25vw;
  background: white;
  font-size: 18px !important;
}

::v-deep table {
  background-color: #fafafa;
}

::v-deep table tbody tr td {
  font-size: 1em !important;
  color: rgb(88, 88, 88);
  font-weight: normal;
}

@media only screen and (min-width: 850px) {
  ::v-deep table tbody tr td {
    font-size: 0.9em !important;
    color: rgb(88, 88, 88);
    font-weight: bold;
  }
}
</style>
